@import '../styles/main.scss';
$button-color: #f6d9d5; // Add this line
.featured-service-card {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  text-align: center;
  width: 100%;
  max-width: 300px;
  margin: 0 auto;

  &:hover {
    transform: translateY(-5px);
  }

  .featured-service-image {
    width: 100%;
    height: 0;
    padding-bottom: 66.67%; // Maintain a 3:2 aspect ratio
    overflow: hidden;
    border-radius: 10px;
    margin-bottom: 15px;
    position: relative;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .featured-service-content {
  .btn {
      @include button-styles;
    }
    h3 {
      font-size: 20px;
      margin-bottom: 10px;
    }

    p {
      font-size: 14px;
      margin-bottom: 15px;
    }

    .btn {
      display: inline-block;
      padding: 8px 16px;
      background-color: #d4af37;
      color: #fff;
      text-decoration: none;
      border-radius: 5px;
      transition: background-color 0.3s ease;
      font-size: 14px;

      &:hover {
        background-color: darken(#d4af37, 10%);
      }
    }
  }
}

.btn {
  display: inline-block;
  padding: 15px 30px;
  background-color: darken($button-color, 10%);
  color: #333;
  text-decoration: none;
  font-weight: bold;
  border-radius: 30px;
  transition: background-color 0.3s;
  
  &:hover {
    background-color: darken($button-color, 20%);
  }
}

