.home {
  .hero {
    background-image: url('../assets/spa_home.jpeg');
    background-size: cover;
    background-position: center;
    padding: 120px 20px;
    text-align: center;
    color: #fff;

    .cta-button {
      @include button-styles;
    }

    h1 {
      font-size: 54px;
      margin-bottom: 20px;
    }

    p {
      font-size: 24px;
      margin-bottom: 30px;
    }

    .hero-description {
      max-width: 800px;
      margin: 0 auto;
      background-color: rgba(0, 0, 0, 0.6);
      padding: 20px;
      border-radius: 10px;

      p {
        font-size: 18px;
        line-height: 1.6;
        margin-bottom: 0;
      }
    }
  }

  .featured-services {
    padding: 80px 0;
    text-align: center;
    background-color: #f6d9d5;

    h2 {
      font-size: 36px;
      margin-bottom: 40px;
    }

    .service-grid {
      display: flex;
      justify-content: center;
      gap: 30px;
      flex-wrap: wrap;

      .featured-service-item {
        flex-basis: calc(33.33% - 20px);
        background-color: #fff;
        padding: 30px;
        border-radius: 10px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s ease;

        &:hover {
          transform: translateY(-5px);
        }

        .featured-service-image {
          width: 100%;
          height: 0;
          padding-bottom: 66.67%; // Maintain a 3:2 aspect ratio
          overflow: hidden;
          border-radius: 10px;
          margin-bottom: 20px;
          position: relative;

          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        h3 {
          font-size: 24px;
          margin-bottom: 10px;
        }

        p {
          font-size: 16px;
          margin-bottom: 20px;
        }

        .btn {
          @include button-styles;
        }
      }
    }
  }

  .testimonials {
    background-color: #f9f9f9;
    padding: 80px 20px;
    text-align: center;

    h2 {
      font-size: 36px;
      margin-bottom: 40px;
    }

    .testimonial-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 40px;

      blockquote {
        font-style: italic;
        font-size: 18px;
        color: #666;
      }
    }
  }
}
