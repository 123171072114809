.packages {
  background-color: $secondary-color;
  padding: 100px 0;

  h2 {
    text-align: center;
    margin-bottom: 40px;
  }

  .package-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;

    .package-item {
      background-color: #fff;
      padding: 30px;
      border-radius: 10px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      text-align: center;

      h3 {
        font-size: 24px;
        margin-bottom: 20px;
      }

      p {
        margin-bottom: 20px;
      }

      .package-price {
        font-size: 36px;
        font-weight: bold;
        color: $primary-color;
        margin-bottom: 30px;
      }
    }
  }
}
