.booking {
  background-color: #f9f9f9;

  .booking-form {
    max-width: 600px;
    margin: 0 auto;

    .form-group {
      margin-bottom: 1.5rem;

      label {
        display: block;
        margin-bottom: 0.5rem;
        font-weight: bold;
      }

      input,
      select,
      textarea {
        width: 100%;
        padding: 0.75rem;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 1rem;
      }
    }

    button[type='submit'] {
      display: block;
      width: 100%;
      padding: 1rem;
      background-color: $button-color;
      color: #fff;
      font-weight: bold;
      border: none;
      border-radius: 30px;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: darken($button-color, 10%);
      }
    }
  }

  .success-message {
    color: $success-color;
    margin-top: 1rem;
    text-align: center;
  }
}
