.gallery {
  padding: 40px 0;
  background-color: #f6d9d5;

  .gallery-section {
    margin-bottom: 60px;

    h2 {
      text-align: center;
      margin-bottom: 30px;
      color: #444;
      font-size: 1.8rem;
    }

    .gallery-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      gap: 20px;
      padding: 20px;
      
      .gallery-item {
        position: relative;
        overflow: hidden;
        border-radius: 10px;
        box-shadow: 0 2px 4px rgba(0,0,0,0.1);
        aspect-ratio: 3/2;

        &:nth-child(1), &:nth-child(2) {
          aspect-ratio: auto;
          height: auto;
          
          img {
            height: auto;
            aspect-ratio: auto;
          }
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: transform 0.3s ease;

          &:hover {
            transform: scale(1.05);
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding: 20px 10px;

    .gallery-section {
      margin-bottom: 40px;

      h2 {
        font-size: 1.5rem;
      }

      .gallery-grid {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        gap: 15px;
        padding: 10px;

        .gallery-item {
          &:nth-child(1), &:nth-child(2) {
            grid-column: span 2;
          }
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    .gallery-grid {
      grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
      gap: 10px;

      .gallery-item {
        &:nth-child(1), &:nth-child(2) {
          grid-column: span 1;
        }
      }
    }
  }
}
