* {
 margin: 0;
 padding: 0;
 box-sizing: border-box !important;
}

body, html {
 margin: 0 !important;
 padding: 0 !important;
 overflow-x: hidden !important;
 width: 100% !important;
 position: relative !important;
}

.App {
 overflow-x: hidden !important;
 width: 100% !important;
 position: relative !important;
}

.header {
 width: 100vw !important;
 position: relative;
 background-color: white;
 margin: 0 !important;
 padding: 0 !important;
 overflow: hidden !important;
 left: 50% !important;
 right: 50% !important;
 margin-left: -50vw !important;
 margin-right: -50vw !important;

 .top-banner {
   width: 100% !important;
   margin: 0 !important;
   padding: 0 !important;
   background-color: white;
   position: relative;
   overflow: hidden !important;
   
   .logo-container {
     width: 100% !important;
     display: flex;
     justify-content: center;
     align-items: center;
     background-color: white;
     overflow: hidden !important;
     margin: 0 !important;
     padding: 0 !important;
     
     .logo {
       &.desktop-logo {
         width: 100% !important;
         max-width: 1200px !important;
         height: 200px !important;
         object-fit: contain;
         display: block;
         margin: 0 !important;
         padding: 0 !important;
         border-radius: 15px !important;
         
         @media (max-width: 768px) {
           display: none;
         }
       }
       
       &.mobile-logo {
         display: none;
         width: 100% !important;
         max-width: 600px !important;
         height: 120px !important;
         object-fit: contain;
         margin: 0 !important;
         padding: 0.5rem !important;
         border-radius: 10px !important;
         
         @media (max-width: 768px) {
           display: block;
         }
       }
     }
   }

   .desktop-nav {
     position: absolute;
     right: 2rem;
     top: 50%;
     transform: translateY(-50%);
     background: #333;
     padding: 0.75rem 2rem;
     border-radius: 35px;
     z-index: 10;

     @media (max-width: 768px) {
       display: none;
     }

     ul {
       display: flex;
       gap: 2rem;
       list-style: none;
       margin: 0;
       padding: 0;
       
       a {
         color: white;
         text-decoration: none;
         font-weight: 500;
         
         &.book-now {
           background-color: #8B7355;
           padding: 0.75rem 1.5rem;
           border-radius: 25px;
           transition: background-color 0.3s;

           &:hover {
             background-color: darken(#8B7355, 10%);
           }
         }

         &:hover {
           color: rgba(255, 255, 255, 0.8);
         }
       }
     }
   }

   .mobile-menu-btn {
     display: none;
     
     @media (max-width: 768px) {
       display: block;
       width: 50px;
       height: 50px;
       padding: 10px;
       background: #333;
       border: none;
       border-radius: 8px;
       position: absolute;
       right: 1.5rem;
       top: 50%;
       transform: translateY(-50%);
       z-index: 1000;
       cursor: pointer;

       span {
         display: block;
         width: 30px;
         height: 3px;
         background-color: white;
         margin: 6px auto;
         transition: all 0.3s;
       }

       &.open {
         span {
           &:first-child {
             transform: rotate(45deg) translate(8px, 8px);
           }
           
           &:nth-child(2) {
             opacity: 0;
           }
           
           &:last-child {
             transform: rotate(-45deg) translate(7px, -7px);
           }
         }
       }
     }
   }
 }

 .mobile-nav {
   display: none;
   
   @media (max-width: 768px) {
     &.open {
       display: block;
       width: 100%;
       background: #333;
       border-radius: 0 0 15px 15px;

       ul {
         list-style: none;
         padding: 1rem;
         margin: 0;
         text-align: center;

         li {
           padding: 0.75rem;
           
           a {
             color: white;
             text-decoration: none;
             font-size: 1.1rem;
             display: block;

             &.book-now {
               background: #8B7355;
               padding: 0.75rem;
               border-radius: 8px;
               margin: 0.5rem auto;
               max-width: 200px;
               transition: background-color 0.3s;

               &:hover {
                 background-color: darken(#8B7355, 10%);
               }
             }

             &:hover {
               color: rgba(255, 255, 255, 0.8);
             }
           }
         }
       }
     }
   }
 }
}

// Additional overrides for any potential conflicting styles
.header * {
 box-sizing: border-box !important;
}

.logo-container img {
 max-width: 100% !important;
 height: auto !important;
}
