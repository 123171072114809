.reviews {
  padding: 100px 0;
  
  h2 {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .review-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
    
    .review-item {
      background-color: #fff;
      padding: 30px;
      border-radius: 10px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      
      .review-text {
        font-size: 18px;
        line-height: 1.6;
        margin-bottom: 20px;
      }
      
      .review-author {
        font-weight: bold;
        text-align: right;
      }
    }
  }
  
  @media screen and (max-width: 768px) {
    .review-grid {
      grid-template-columns: 1fr;
    }
  }
}
