.services {
  background-color: #f9f9f9;

  .service {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 2rem;

    img {
      width: 100%;
      max-width: 400px;
      object-fit: cover;
      border-radius: 10px;
      margin-bottom: 1rem;
    }

    .service-details {
      flex: 1;
      padding-left: 2rem;

      .service-item {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;

        .service-thumbnail {
          width: 80px;
          height: 80px;
          object-fit: cover;
          border-radius: 10px;
          margin-right: 1rem;
        }

        .service-description {
          flex: 1;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .services {
    .service {
      flex-direction: column;

      img {
        max-width: 100%;
      }

      .service-details {
        padding-left: 0;
        margin-top: 1rem;
      }
    }
  }
}
