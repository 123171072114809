// _contact.scss
.contact {
    padding: 40px 20px;
    background-color: #f6d9d5;
    text-align: center;

    h1 {
        font-size: 2.5rem;
        margin-bottom: 30px;
        color: #333;
    }

    .booking-options {
        max-width: 800px;
        margin: 0 auto;

        h2 {
            font-size: 2rem;
            margin-bottom: 20px;
            color: #333;
        }

        p {
            font-size: 1.1rem;
            margin-bottom: 30px;
            color: #666;
        }

        .booking-list {
            text-align: left;
            margin-bottom: 30px;
        }

        .booking-item {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            padding: 15px;
            background-color: white;
            border-radius: 10px;
            box-shadow: 0 2px 10px rgba(0,0,0,0.1);
            text-decoration: none;
            transition: transform 0.3s ease, box-shadow 0.3s ease;

            &:hover {
                transform: translateY(-3px);
                box-shadow: 0 4px 15px rgba(0,0,0,0.15);
            }

            svg {
                font-size: 1.5rem;
                color: #d4af37;
                margin-right: 15px;
                flex-shrink: 0;
            }

            span {
                font-size: 1rem;
                color: #666;

                strong {
                    color: #333;
                }
            }
        }

        .booking-item.line-booking {
            .line-content {
                display: flex;
                align-items: center;
                gap: 20px;
                flex: 1;
            }

            .line-qr {
                width: 100px;
                height: 100px;
                object-fit: contain;
                border-radius: 8px;
            }
        }

        .welcome-message {
            font-size: 1.2rem;
            font-weight: bold;
            color: #333;
            margin-top: 30px;
        }
    }
}

@media (max-width: 768px) {
    .contact {
        .booking-options {
            .booking-item {
                flex-direction: column;
                align-items: flex-start;

                svg {
                    margin-bottom: 10px;
                }
            }

            .booking-item.line-booking {
                .line-content {
                    flex-direction: column;
                    align-items: flex-start;
                }

                .line-qr {
                    margin-top: 10px;
                    width: 150px;
                    height: 150px;
                }
            }
        }
    }
}
