.footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 40px;
  background-color: #f9f9f9;

.social-media {
    a {
      margin-right: 10px;
      color: #333;
      font-size: 24px;
      transition: color 0.3s;

      &:hover {
        color: #d4af37;
      }
    }
  }
  .contact-info,
  .social-media,
  .newsletter {
    flex-basis: 30%;
    margin-bottom: 20px;

    h3 {
      font-size: 24px;
      margin-bottom: 15px;
      color: #333;
    }

    p {
      margin-bottom: 10px;
      color: #666;
    }

    a {
      margin-right: 10px;
      color: #333;
      font-size: 24px;
      transition: color 0.3s;

      &:hover {
        color: #d4af37;
      }
    }

    form {
      display: flex;

      input[type='email'] {
        flex: 1;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 30px 0 0 30px;
      }

button {
  @include button-styles;
  border-radius: 0 30px 30px 0;
}
    }
  }

  .copyright {
    flex-basis: 100%;
    text-align: center;
    margin-top: 20px;
    color: #666;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    text-align: center;

    .contact-info,
    .social-media,
    .newsletter {
      flex-basis: 100%;
      margin-bottom: 30px;
    }
  }
}
