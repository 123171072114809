@mixin button-styles {
  display: inline-block;
  padding: 15px 30px;
  background-color: $primary-color; // Changed this line
  color: #fff; // Changed this line to ensure contrast
  font-family: 'Playfair Display', serif;
  font-size: 18px;
  text-decoration: none;
  font-weight: bold;
  border-radius: 30px;
  transition: background-color 0.3s;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: darken($primary-color, 10%); // Changed this line
  }
}

@import 'variables';
@import 'header';
@import 'footer';
@import 'home';
@import 'about';
@import 'services';
@import 'products';
@import 'booking';
@import 'gallery';
@import 'contact';
@import 'packages';
@import 'reviews';
@import 'gift-cards';

body {
  font-family: 'Playfair Display', serif;
  color: $text-color;
  line-height: 1.6;
}

h1, h2, h3 {
  font-family: 'Lato', sans-serif;
  font-weight: bold;
  margin-bottom: 1rem;
}

h1 { font-size: 2.5rem; }
h2 { font-size: 2rem; }
h3 { font-size: 1.5rem; }

p { margin-bottom: 1.5rem; }

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

section {
  padding: 4rem 0;
  &:nth-child(even) { background-color: $secondary-color; }
}

.service {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 2rem;

  img {
    width: 100%;
    max-width: 400px;
    object-fit: cover;
    border-radius: 10px;
    margin-bottom: 1rem;
  }

  .service-details {
    flex: 1;
    padding-left: 2rem;

    .service-item {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;

      .service-thumbnail {
        width: 80px;
        height: 80px;
        object-fit: cover;
        border-radius: 10px;
        margin-right: 1rem;
      }

      .service-description { flex: 1; }
    }
  }
}

// New styles for combo sets
.combo-sets {
  background-color: $tertiary-color;
  padding: 2rem;
  border-radius: 10px;
  margin-bottom: 2rem;

  h2 {
    text-align: center;
    margin-bottom: 1.5rem;
  }

  .service-item {
    background-color: #fff;
    padding: 1.5rem;
    border-radius: 8px;
    margin-bottom: 1.5rem;

    h3 {
      color: $primary-color;
      margin-bottom: 1rem;
    }

    ul {
      list-style-type: none;
      padding-left: 0;

      li {
        margin-bottom: 0.5rem;
      }
    }
  }
}

.back-to-top {
  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;
  display: none;
  width: 40px;
  height: 40px;
  background-color: $primary-color;
  color: #fff;
  border: none;
  border-radius: 50%;
  font-size: 1.5rem;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover { background-color: darken($primary-color, 10%); }

  &.show {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 768px) {
  h1 { font-size: 2rem; }
  h2 { font-size: 1.8rem; }
  h3 { font-size: 1.3rem; }

  section { padding: 3rem 0; }

  .service {
    flex-direction: column;

    img { max-width: 100%; }

    .service-details {
      padding-left: 0;
      margin-top: 1rem;
    }
  }

  .combo-sets {
    padding: 1.5rem;

    .service-item {
      padding: 1rem;
    }
  }
}

@media screen and (max-width: 480px) {
  h1 { font-size: 1.8rem; }
  h2 { font-size: 1.6rem; }
  h3 { font-size: 1.2rem; }

  section { padding: 2rem 0; }
}

.btn { @include button-styles; }

.success-message {
  color: $success-color;
  font-size: 1rem;
  margin-top: 1rem;
}
.qr-codes-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin: 2rem 0;
}

.qr-code {
  display: flex;
  flex-direction: column;
  align-items: center;
  
  img {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 10px;
  }

  p {
    margin-top: 0.5rem;
    font-weight: bold;
  }
}

@media (max-width: 768px) {
  .qr-codes-container {
    flex-direction: column;
  }
}
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1 0 auto;
}

.footer {
  flex-shrink: 0;
}
