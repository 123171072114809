.about {
  padding: 80px 20px;

  .intro {
    text-align: center;
    margin-bottom: 60px;

    h1 {
      font-size: 48px;
      margin-bottom: 20px;
    }

    p {
      font-size: 18px;
      color: #666;
      margin-bottom: 15px;
    }
  }

  .founder {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 60px;

    img {
      width: 200px;
      height: 200px;
      border-radius: 50%;
      object-fit: cover;
      margin-bottom: 20px;
    }

    h2 {
      font-size: 36px;
      margin-bottom: 20px;
    }

    p {
      font-size: 18px;
      color: #666;
      margin-bottom: 15px;
      text-align: center;
      max-width: 600px;
    }
  }

  .philosophy {
    text-align: center;

    h2 {
      font-size: 36px;
      margin-bottom: 20px;
    }

    p {
      font-size: 18px;
      color: #666;
      margin-bottom: 15px;
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
