.products {
  background-color: $tertiary-color;
  padding: 100px 0;

  .product-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 40px;

    .product-item {
      background-color: #fff;
      padding: 30px;
      border-radius: 10px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s;

      &:hover {
        transform: translateY(-5px);
      }

      img {
        width: 100%;
        height: auto;
        margin-bottom: 20px;
        border-radius: 10px;
      }

      h3 {
        font-size: 20px;
        margin-bottom: 10px;
      }

      p {
        color: $light-text-color;
        margin-bottom: 20px;
      }

      .product-price {
        font-size: 18px;
        font-weight: bold;
        color: $primary-color;
        margin-bottom: 20px;
      }

 .add-to-cart {
        display: inline-block;
        padding: 12px 24px;
        background-color: $primary-color;
        color: #fff;
        text-decoration: none;
        font-weight: bold;
        border-radius: 30px;
        transition: background-color 0.3s;

        &:hover {
          background-color: darken($primary-color, 10%);
        }
      }
    }
  }
}
