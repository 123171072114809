.gift-cards {
  background-color: $tertiary-color;
  padding: 100px 0;
  text-align: center;

  h2 {
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 40px;
  }

  .gift-card-options {
    display: flex;
    justify-content: center;
    gap: 40px;

    .gift-card-item {
      h3 {
        font-size: 24px;
        margin-bottom: 20px;
      }
    }
  }
}
